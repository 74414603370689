.faq {
  &__list {
    margin-top: 6rem;

    @media (max-width: em(992)) {
      margin-top: 4rem;
    }

    @media (max-width: em(767)) {
      margin-top: 2rem;
    }
  }

  &__item {
    transition: padding-bottom var(--transition);

    &-title {
      position: relative;
      border-radius: 0.7rem;
      padding: 2.2rem 7rem 2.2rem 4.5rem;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 150%;
      background-color: var(--clr-primary);
      cursor: pointer;

      @media (max-width: em(767)) {
        padding: 1.5rem 4.5rem 1.5rem 1.5rem;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: #f7c746;
      }

      &::before {
        right: 5rem;
        width: 2rem;
        height: 0.2rem;
        top: 3.2rem;

        @media (max-width: em(767)) {
          top: 2.4rem;
          right: 1.5rem;
        }
      }

      &::after {
        right: 5.9rem;
        width: 0.2rem;
        top: 2.2rem;
        height: 2rem;
        transition: height var(--transition);

        @media (max-width: em(767)) {
          top: 1.5rem;
          right: 2.4rem;
        }
      }
    }

    &-body {
      overflow: hidden;
      padding-right: 4.5rem;
      padding-left: 4.5rem;
      max-height: 0;
      box-sizing: content-box;
      line-height: 155%;
      color: var(--clr-default-100);
      background-color: var(--clr-bg);
      transition: max-height var(--transition), padding-top var(--transition), padding-bottom var(--transition);

      @media (max-width: em(767)) {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }

    &--active {
      .faq__item-body {
        padding-top: 2.5rem;
        padding-bottom: 1rem;
        transition: padding-top var(--transition), padding-bottom var(--transition);

        @media (max-width: em(767)) {
          padding-top: 1.5rem;
        }
      }

      .faq__item-title::after {
        height: 0;
        transition: height var(--transition);
      }
    }
  }

  &__item + &__item {
    margin-top: 3rem;

    @media (max-width: em(767)) {
      margin-top: 1.5rem;
    }
  }
}
