$burgerMQ: em(1300);

.menu {
  border-radius: 1.5rem;

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding-block: 4rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &-img {
      display: inline-block;
      margin-right: 1.5rem;

      img {
        max-width: 2.1rem;
      }
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    color: rgb(255 255 255 / 70%);
    transition: color var(--transition);

    @media (hover) {
      &:hover {
        color: var(--clr-default-200);
        transition: color var(--transition);
      }
    }
  }

  /*@media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 320px;
    background-color: var(--clr-default-900);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }*/
}
