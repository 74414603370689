.pros {
  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    flex: 0 1 49%;
    overflow: hidden;
    border-radius: 3.5rem;
    padding: 11.5rem 4rem 5.5rem;
    box-shadow: -20px 35px 50px rgb(14 8 17 / 80%);
    background-color: var(--clr-primary);

    @media (max-width: em(992)) {
      padding: 9.5rem 3rem 3rem;
    }

    @media (max-width: em(576)) {
      padding: 9.5rem 1.5rem 2.5rem;
    }

    // Circle
    &::before {
      content: "";
      position: absolute;
      right: -4rem;
      bottom: -12rem;
      width: 43rem;
      height: 43rem;
      filter: blur(160px);

      @media (max-width: 992px) {
        display: none;
      }
    }

    li {
      position: relative;
      padding-left: 1.8rem;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.3rem;
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
      }

      @media (max-width: em(767)) {
        padding-left: 1.2rem;
      }
    }

    li + li {
      margin-top: 2.5rem;
    }

    &--pros {
      // circle
      &::before {
        background-color: rgb(104 242 60 / 11%);
      }

      .pros__item--label {
        background-color: rgb(87 167 61 / 30%);
      }

      li {
        &::before {
          background-color: #57a73d;
        }
      }
    }

    &--cons {
      // circle

      &::before {
        background-color: rgb(242 125 60 / 11%);
      }

      .pros__item--label {
        background-color: rgb(167 86 61 / 20%);
      }

      li {
        &::before {
          background-color: #a7563d;
        }
      }
    }

    &--label {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 3.5rem;
      border-bottom-right-radius: 2rem;
      width: 13rem;
      height: 8rem;
      font-weight: 600;
      font-size: fluid-font(2.4rem, 2rem);
    }
  }

  &__item + &__item {
    @media (max-width: em(767)) {
      margin-top: 1.5rem;
    }
  }
}
