.providers {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column-reverse;
    }
  }

  &__img {
    max-width: 34rem;
  }

  &__info {
    margin-left: 1.5rem;
    max-width: 43.5rem;

    @media (max-width: em(767)) {
      margin-left: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }
}
