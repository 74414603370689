.vpn {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 2.2rem;
    padding: 5rem 5.5rem;
    background-color: var(--clr-primary);

    @media (max-width: em(992)) {
      padding: 2.5rem 3rem;
    }

    @media (max-width: em(767)) {
      flex-direction: column;
      padding: 2.5rem 1.5rem;
      margin-left: -1.5rem;
      border-radius: initial;
      width: calc(100% + 3rem);
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 1rem;
      width: 19rem;
      height: 19rem;
      filter: blur(16rem);

      @media (max-width: 992px) {
        display: none;
      }
    }

    // Circle
    &::before {
      left: -7rem;
      top: -6rem;
      background-image: linear-gradient(109.45deg, #ffcb6c 22.4%, #e2a433 77.68%);
    }

    // Circle
    &::after {
      right: -2rem;
      bottom: 2rem;
      background-image: linear-gradient(109.45deg, rgb(255 203 108 / 70%) 22.4%, rgb(226 164 51 / 70%) 77.68%);
    }
  }

  &__info {
    z-index: 1;
    margin-right: 1.5rem;
    max-width: 52rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    z-index: 1;
    max-width: 22.5rem;
  }
}
