.live {
  padding-top: 15rem;

  @media (max-width: em(992)) {
    padding-top: 10rem;
  }

  @media (max-width: em(767)) {
    padding-top: 6rem;
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 44rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    position: relative;
    max-width: 46rem;

    // Circle
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: -1;
      width: 33.6rem;
      height: 25rem;
      background-color: #722762;
      filter: blur(160px);
      transform: translate(-50%, -50%);

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
