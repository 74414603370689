.bonuses {
  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(9, 1fr);
    margin-top: 4rem;

    @media (max-width: em(992)) {
      grid-gap: 1.5rem;
      margin-top: 2rem;
    }

    @media (max-width: em(767)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    min-height: 28rem;
    border-radius: 2.2rem;
    padding: 3.5rem 3rem;
    background-color: var(--clr-primary);

    @media (max-width: em(992)) {
      padding: 2.5rem 2rem;
    }

    @media (max-width: em(576)) {
      padding: 1.5rem;
      min-height: 18rem;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &-title {
      position: relative;
      z-index: 1;
      margin-bottom: 1.5rem;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 130%;
    }

    &-text {
      position: relative;
      z-index: 1;
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 90%);
    }

    &:first-child {
      grid-column: 1/6;

      &::after {
        width: 47rem;
        height: 8.3rem;
        background-image: url("../img/ui/img6@1x.svg");
      }
    }

    &:last-child {
      grid-column: 7/10;

      &::after {
        width: 22.2rem;
        height: 8.9rem;
        background-image: url("../img/ui/img5@1x.svg");
      }
    }

    &:nth-child(2) {
      grid-column: 6/10;

      &::after {
        right: 0;
        top: 0;
        bottom: initial;
        width: 31.4rem;
        height: 19.1rem;
        background-image: url("../img/ui/img3@1x.svg");
      }
    }

    &:nth-child(3) {
      grid-column: 1/4;

      &::after {
        width: 26.5rem;
        height: 13.5rem;
        background-image: url("../img/ui/img4@1x.svg");
      }
    }

    &:nth-child(4) {
      grid-column: 4/7;

      &::after {
        width: 20.8rem;
        height: 10.3rem;
        background-image: url("../img/ui/img2@1x.svg");
      }
    }

    &:first-child, &:last-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      @media (max-width: em(767)) {
        grid-column: initial;
      }
    }
  }

  &__item + &__item {
    @media (max-width: em(576)) {
      margin-top: 1.5rem;
    }
  }
}
