$burgerMQ: em(1300);

.burger {
  $width: 4rem;
  $height: 4rem;
  $bgColor: var(--clr-default-900);
  $burgerLineHeight: 0.2rem;

  display: none;

  @media (max-width: $burgerMQ) {
    position: absolute;
    right: -4rem;
    top: 2rem;
    z-index: 15;
    display: block;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    width: $width;
    height: $height;
    background-color: var(--clr-default-200);

    &::after,
    &::before,
    span {
      content: "";
      position: absolute;
      left: 50%;
      width: 3rem;
      height: $burgerLineHeight;
      background-color: $bgColor;
      transform: translateX(-50%);
    }

    &::before {
      top: 1rem;
      transition: transform var(--transition), top var(--transition);
    }

    &::after {
      bottom: 1rem;
      transition: transform var(--transition), bottom var(--transition);
    }

    span {
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform var(--transition);
    }

    &--active {
      right: 0;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: initial;
      border-bottom-right-radius: initial;
      border-bottom-left-radius: 0.4rem;

      &::before {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: transform var(--transition), top var(--transition);
      }

      &::after {
        bottom: 50%;
        transform: translate(-50%, 50%) rotate(-45deg);
        transition: transform var(--transition), bottom var(--transition);
      }

      span {
        transform: scale(0);
        transition: transform var(--transition);
      }
    }
  }
}
