.vip {
  &__info {
    margin-right: 1.5rem;
    max-width: 37rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    max-width: 47rem;
    filter: drop-shadow(-15px 30px 40px rgb(14 8 17 / 60%));

    img {
      border-radius: 3rem;
    }
  }
}
