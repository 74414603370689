.methods {
  &__list {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }

  &__item {
    position: relative;
    padding-left: 2rem;

    &::before {
      content: '';
      position: absolute;
      top: 0.6rem;
      left: 0;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #a73c90;
    }
  }

  &__item + &__item {
    margin-top: 1rem;
  }
}
