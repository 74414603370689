.mobile {
  &__info {
    margin-right: 1.5rem;
    max-width: 47rem;

    @media (max-width: em(767)) {
      margin-left: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    position: relative;
    max-width: 38rem;

    // Circle
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: -1;
      width: 25rem;
      height: 25rem;
      background-color: rgb(114 39 98 / 40%);
      filter: blur(160px);
      transform: rotate(15.06deg) translate(-50%, -50%);

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
