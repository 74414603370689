.hero {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 5rem;
  background-color: var(--clr-default-900);
  background-image: url('../img/hero/img@2x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__info {
    max-width: 87rem;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__title {
    font-weight: 700;
    font-size: fluid-font(4.2rem, 3.4rem);
    line-height: 125%;
  }

  .section__text {
    margin-top: 4rem;
    margin-bottom: 3rem;

    @media (max-width: em(767)) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}
