.slots {
  .section__title {
    margin-bottom: 3rem;

    @media (max-width: em(767)) {
      margin-bottom: 2rem;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 41rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  .section__text {
    margin-bottom: 5.5rem;

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__img {
    position: relative;
    max-width: 46rem;

    // Circle
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 25rem;
      height: 25rem;
      filter: blur(160px);
      z-index: -1;
      background-color: #722762;
      transform: translate(-50%, -50%);

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
