.reviews {
  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 6rem;

    @media (max-width: em(1200)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(992)) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 3rem;
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    border-radius: 2.2rem;
    padding: 3rem 3rem 12rem;
    background-color: var(--clr-primary);

    @media (max-width: em(767)) {
      padding: 1.5rem 1.5rem 9rem;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 25.4rem;
      height: 15.5rem;
      background-image: url("../img/ui/img1@1x.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &-title {
      margin-bottom: 2rem;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 130%;
    }

    &-text {
      position: relative;
      z-index: 1;
      font-family: "Gilroy", sans-serif;
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 90%);
    }
  }

  &__item + &__item {
    @media (max-width: em(576)) {
      margin-top: 1.5rem;
    }
  }
}
