.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  max-width: 32rem;

  &__wrapper {
    overflow: auto;
    padding-top: 3rem;
    height: 100%;
    background-color: var(--clr-primary);
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 2rem 3rem 6rem;
    margin-right: auto;
    margin-left: auto;
    border-radius: 1.5rem;
    width: 100%;
    max-width: 27.5rem;
    background-color: var(--clr-bg);
  }

  .section__btn {
    height: 4.7rem;
    min-width: 21rem;
  }

  @media (max-width: em(1300)) {
    left: -32rem;
    transition: left var(--transition);

    &--active {
      left: 0;
      transition: left var(--transition);
    }
  }
}
