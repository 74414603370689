.license {
  &__info {
    margin-right: 1.5rem;
    max-width: 38rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__validation {
    border-radius: 2.2rem;
    padding: 3rem 4rem;
    width: 100%;
    max-width: 43.5rem;
    box-shadow: -15px 30px 40px rgb(14 8 17 / 60%);
    background-color: var(--clr-primary);

    @media (max-width: em(992)) {
      padding: 2.5rem 2rem;
    }

    @media (max-width: em(576)) {
      padding: 2rem 1.5rem;
    }

    &-title {
      font-weight: 600;
      font-size: fluid-font(2.4rem, 2rem);
      line-height: 155%;
    }

    &-subtitle {
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 70%);
    }

    &-info {
      margin-top: 3rem;
      margin-bottom: 5rem;
      font-size: 1.4rem;

      @media (max-width: em(767)) {
        margin-top: 2rem;
        margin-bottom: 2.5rem;
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-name {
      margin-right: 1.5rem;
      line-height: 100%;
    }

    &-value {
      text-align: right;
      color: rgb(255 255 255 / 90%);
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 0.5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width: 100%;
      height: 4.8rem;
      color: #1f1f20;
      background-color: rgb(255 203 108 / 70%);
      transition: box-shadow var(--transition);

      @media (hover) {
        &:hover {
          box-shadow: 0 15px 35px rgb(94 67 15 / 20%);
          transition: box-shadow var(--transition);
        }
      }
    }

    &-text {
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 70%);
    }

    &-inner + &-inner {
      margin-top: 1.5rem;
    }
  }
}
