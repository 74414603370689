.deposit {
  position: relative;
  background-image: url('../img/bonuses/img@1x.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__title {
    font-weight: 700;
    font-size: fluid-font(4.2rem, 3.2rem);
    line-height: 125%;
  }
}
